import request from '@/utils/request'


// 查询客服商户列表
export function listSeller(query) {
  return request({
    url: '/customer/seller/list',
    method: 'get',
    params: query
  })
}


// 查询客服商户详细
export function getSeller(data) {
  return request({
    url: '/customer/seller/detail',
    method: 'get',
    params: data
  })
}

// 新增客服商户
export function addSeller(data) {
  return request({
    url: '/customer/seller/add',
    method: 'post',
    data: data
  })
}

// 修改客服商户
export function updateSeller(data) {
  return request({
    url: '/customer/seller/edit',
    method: 'post',
    data: data
  })
}

// 删除客服商户
export function delSeller(data) {
  return request({
    url: '/customer/seller/delete',
    method: 'post',
    data: data
  })
}

// 查询客服商户详细
export function getSellerSignUrl(data) {
  return request({
    url: '/customer/seller/sign-url',
    method: 'get',
    params: data
  })
}

// 打开客服管理平台
export function openCustomerSetting(data) {
  return request({
    url: '/customer/seller/open-customer-setting',
    method: 'get',
    params: data
  })
}
